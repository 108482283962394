import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Meta, Seo, Header, Footer } from "../components";

export default function Layout({ children }) {
  return (
    <div class="Site">
      <Seo />
      <Meta />
      <Header />
      <div class="Site-content">
        <div className="container">{children}</div>
      </div>
      <Footer />
    </div>
  );
}
